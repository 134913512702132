@use '@/styles/utils/mixins.scss' as *;
.headerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding: 0 10rem;

  @include mob() {
    padding: 0 0.94rem;
  }
}
